import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Button, Section, Strong, Image, Icon, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdRadioButtonUnchecked } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PROLEARNING
			</title>
			<meta name={"description"} content={"Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!"} />
			<meta property={"og:title"} content={"PROLEARNING"} />
			<meta property={"og:description"} content={"Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
		</Helmet>
		<Components.Heder2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
		</Components.Heder2>
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.9) 0%,rgba(5, 7, 33, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/67448839e5d277001899b0c5/images/web-development.jpg?v=2024-11-25T14:33:29.509Z) center/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(228, 232, 236, 0.15)"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#d4dae0"
						font="normal 300 16px/1.5 --fontFamily-sans"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
					>
						<Em>
							Навчання веб-розробці для тих, хто хоче більше
						</Em>
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 60px 0px 60px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					<Em
						font="--headline1"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!
					</Em>
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					ProLearning24 — це ваш стартовий майданчик для успішної кар'єри у веб-розробці. Наші курси створені для новачків і професіоналів, які прагнуть стати затребуваними спеціалістами. Ми надаємо доступ до сучасних методик навчання, практичних завдань та експертних інструкторів.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/about"
					>
						Послуги
					</Button>
					<Button
						margin="0px 0 0px 0px"
						padding="12px 28px 12px 28px"
						background="rgba(0, 119, 204, 0)"
						color="#d4dae0"
						font="normal 500 17px/1.5 --fontFamily-sans"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--orange" font="--lead" md-margin="0px 0px 20px 0px">
					Про нас
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					<Strong>
						Чому обирають ProLearning24?
					</Strong>
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0 0px" color="--light" font="--base">
					Ми віримо, що якісна освіта має бути доступною. Наша мета — допомогти вам здобути навички, необхідні для роботи у сфері веб-розробки. Завдяки нашій платформі ви отримуєте:
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Практичний підхід.
					</Strong>
					{" "}Ми фокусуємось на реальних завданнях, які ви зможете виконувати у майбутній роботі.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Доступ до експертів.
					</Strong>
					{" "}Наші викладачі — професіонали з багаторічним досвідом.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Гнучкість навчання.
					</Strong>
					{" "}Навчайтесь у зручний для вас час і темп.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						ProLearning24 допомагає людям знайти нову професію, змінити кар'єру або вдосконалити свої навички для зростання в IT-сфері.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			overflow-y="hidden"
			overflow-x="hidden"
			quarkly-title="Advantages/Features-12"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					box-shadow="--xl"
					lg-height="auto"
				>
					<Image
						height="589px"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						box-shadow="--xxl"
						src="https://uploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14:33:29.498Z"
						object-fit="cover"
						lg-height="auto"
						lg-display="block"
						srcSet="https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/67448839e5d277001899b0c5/images/Web-development-work-environment.jpg?v=2024-11-25T14%3A33%3A29.498Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 20px 0px"
				sm-margin="32px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="36px 0px 16px 0px"
			>
				<Text as="p" font="--base" color="--orange" margin="0px 0px 8px 0px">
					Переваги
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					<Strong>
						Що ви отримуєте від навчання у нас?
					</Strong>
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdRadioButtonUnchecked}
							size="20px"
							color="--red"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--light"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Strong>
								Актуальні знання.
							</Strong>
							{" "}Ми постійно оновлюємо матеріали, аби відповідати потребам ринку.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdRadioButtonUnchecked}
							size="20px"
							color="--red"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							color="--light"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Strong />
							<Strong>
								Менторська підтримка.
							</Strong>
							{" "}Викладачі завжди готові відповісти на запитання.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdRadioButtonUnchecked}
							size="20px"
							color="--red"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
							color="--light"
						>
							<Strong>
								Практика на реальних проектах.
							</Strong>
							{" "}Ви отримаєте портфоліо, яке вразить роботодавців.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdRadioButtonUnchecked}
							size="20px"
							color="--red"
							margin="5px 0 0 0"
						/>
						<Text
							padding="0"
							margin="0"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
							color="--light"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							/>
							<Strong>
								Кар’єрна підтримка.
							</Strong>
							{" "}Допомога у складанні резюме, проходженні співбесід та пошуку роботи.
						</Text>
					</Box>
				</List>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0">
			<Box
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
			>
				<Box
					display="flex"
					width="35%"
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
				>
					<Text font="normal 600 18px/1.5 --fontFamily-googleInter" color="--orange" margin="0px 0px 0px 0px">
						01
					</Text>
				</Box>
				<Box
					width="65%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
				>
					<Text
						font="--headline1"
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
					>
						Основи веб-розробки.
					</Text>
				</Box>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
			>
				<Box
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
				>
					<Text color="--orange" margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter">
						02
					</Text>
				</Box>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="65%"
				>
					<Text
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
						font="--headline1"
					>
						Front-end розробка.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Box
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
					sm-width="20%"
				>
					<Text margin="0px 0px 0px 0px" font="normal 600 18px/1.5 --fontFamily-googleInter" color="--orange">
						03
					</Text>
				</Box>
				<Box
					display="flex"
					width="65%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
				>
					<Text
						font="--headline1"
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
					>
						Back-end розробка.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Box
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
					display="flex"
					width="35%"
					sm-width="20%"
					empty-min-width="64px"
					empty-min-height="64px"
				>
					<Text font="normal 600 18px/1.5 --fontFamily-googleInter" color="--orange" margin="0px 0px 0px 0px">
						04
					</Text>
				</Box>
				<Box
					display="flex"
					width="65%"
					empty-min-width="64px"
					empty-min-height="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					empty-border-color="LightGray"
				>
					<Text
						md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
						sm-font="normal 700 38px/1.1 &quot;Inter&quot;, sans-serif"
						font="--headline1"
						color="--lightD1"
						margin="0px 0px 0px 0px"
						width="100%"
					>
						Повний курс Full-stack розробки.
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				display="flex"
				flex-wrap="wrap"
				margin="0px 0px 46px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
			/>
			<Button
				margin="0px 15px 0px 0px"
				padding="12px 28px 12px 28px"
				background="#3f24d8"
				border-radius="8px"
				font="normal 400 17px/1.5 --fontFamily-sans"
				sm-margin="0px 0 15px 0px"
				transition="background-color 0.2s ease 0s"
				border-width="2px"
				border-style="#3f24d8"
				border-color="#3f24d8"
				hover-background="rgba(63, 36, 216, 0)"
				type="link"
				text-decoration-line="initial"
				href="/about"
				text-align="center"
			>
				Послуги
			</Button>
		</Section>
		<Components.Footer color="--dark">
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});